body {
  font: 1.4vw "Century Gothic", Futura, sans-serif;
  background-color: #666666;
}

.header {
  height: 4vw;
}

.navbar {
  height: 3vw;
  margin-left:0px;
  margin-right:0px;
  background-color: #000000;
}

.logo {
  height:6vw;
  width: 6vw;
  position: absolute;
  top: 2vw;
  left: 4vw;
}

.mainbody {
  margin: 0 0 0 0;
}

.homepageBody {
  background-image: url("../public/images/mainbodybackground.png");
  background-repeat: no-repeat;
  background-size:contain;
  margin: 0 0 0 0;
  height: 55vw;
}

.contentArea {
  /*height: 55vw; */
  margin: 0 0 0 0;
  padding-top: 2vw;
}

.homeArea {
  /*background-image: url("../public/images/mainbodybackground.png");
  background-repeat: no-repeat;
  background-size:contain;
  margin: 0 0 0 0; */
  /*height: 55vw; */
  /*margin-top: -2vw;*/
}

.navitems {
  text-align: right;
  font: 2.3vw "Century Gothic", Futura, sans-serif;
  color: #FFFFFF;
}

.navitem {
  margin-left: 10px;
  margin-right: 10px;
}

a.navitem {
  color: #FFFFFF;
  font-weight:bold;
  text-decoration: none;
}
a.navitem:hover {
  color: #666666;
}

.sectionTitle {
  font-size:4vw;
  font-weight:bold;
  color:#FFFFFF;
}

.sectionText {
  color:#ffffff;
  font-weight:normal;
}

.aboutus {
  height:fit-content(5vw);
  padding-bottom: 2vw;
  background-color:#333333;
}

.contactus {
  height:fit-content(5vw);
  padding-bottom: 2vw;
  background-color:#111111;
}

.footer {
  height:fit-content(3vh);
  padding-bottom: 2vw;
  background-color: #000000;
  color: #ffffff;
}

.footerCompanyInfo{
  margin-top: -10vh;
  left:80vw;
  margin-right:auto;
  float:right;
}

a.footerMenuItem {
  color:#ffffff;
  text-decoration: none;
  font-weight:bold;
}

a.footerMenuItem:hover {
  color:#666666;
}

.footerArea {
  margin-left:2vw;
  padding-top:1vw;
}

.tagArea {
  color:#ffffff;
  position:relative;
}

.leftTag {
  position:relative;
  margin-left:5vw;
  font-size:4vw;
  font-weight:bold;
  margin-top:-25vw;
}

.rightTag {
  margin-left:60vw;
  margin-top:-9.5vw;
  font-size:4vw;
  font-weight:bold;
}

.contactPhone {
  color:#ffffff;
  text-align: right;
  margin-right: 10px;
  font-size:1vw;
  top: 10px;
}

.sectionText {
  margin-left:1vw;
  font-size: 2vw;
}

.formerrors {
  color:crimson;
  font-size:1vw;
}

.textArea {
  width:30vw;
  margin-top:1vw;
  font:1.4vw "Century Gothic", Futura, sans-serif;
}

.formLabel{
  width:30vw;
  text-align:right;
  float:left;
}

.textField {
  width:30vw;
  height:3vw;
}

.myButton {
	box-shadow:inset 0px -3px 7px 0px #29bbff;
	background:linear-gradient(to bottom, #2dabf9 5%, #0688fa 100%);
	background-color:#2dabf9;
	border-radius:3px;
	border:1px solid #0b0e07;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:15px;
	padding:9px 23px;
	text-decoration:none;
	text-shadow:0px 1px 0px #263666;
}
.myButton:hover {
	background:linear-gradient(to bottom, #0688fa 5%, #2dabf9 100%);
	background-color:#0688fa;
}
.myButton:active {
	position:relative;
	top:1px;
}